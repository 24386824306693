<template>
  <div>
    <div class="header">Plan Details</div>

    <div class="docs">
      <a v-for="doc in value.document_links" :key="doc.link" :href="doc.link">
        {{ doc_type(doc.type) }}
      </a>
    </div>

    <div class="etf">
      <span>Early Termination Fee: {{
          value.product.early_termination_fee_text || `$${value.product.early_termination_fee}`
        }}</span>
      <span>(Note: This fee <strong>will not be charged</strong> if you end your contract early because you are moving out)</span>
    </div>

    <div class="marketing">
      <strong>{{ value.product.description }}</strong>
      <span>No hidden fees. The rates shown below already include all fees, discounts, and {{ value.tdsp.name }} delivery charges.</span>
    </div>

    <div class="header">Pricing Details</div>

    <div class="pricing_table">
      <table>
        <thead>
        <tr>
          <th>Monthly Usage</th>
          <th>
            <label>Custom Usage</label>
            <input v-model="usage_level" placeholder="Enter Custom Usage" @keyup="calculate"/>
          </th>
          <th :class="{selected: usage_level === 500}">
            <button @click="usage_level = 500; calculate()">500 kWh</button>
          </th>
          <th :class="{selected: usage_level === 1000}">
            <button @click="usage_level = 1000; calculate()">1000 kWh</button>
          </th>
          <th :class="{selected: usage_level === 2000}">
            <button @click="usage_level = 2000; calculate()">2000 kWh</button>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>Total charges on your bill*</td>
          <td></td>
          <td :class="{selected: usage_level === 500}">{{ pricing_500 && pricing_500.total | currency }}</td>
          <td :class="{selected: usage_level === 1000}">{{ pricing_1000 && pricing_1000.total | currency }}</td>
          <td :class="{selected: usage_level === 2000}">{{ pricing_2000 && pricing_2000.total | currency }}</td>
        </tr>
        <tr>
          <td>Average price per kWh (total ÷ usage)</td>
          <td></td>
          <td :class="{selected: usage_level === 500}">{{ pricing_500 && pricing_500.avg_cents }}¢</td>
          <td :class="{selected: usage_level === 1000}">{{ pricing_1000 && pricing_1000.avg_cents }}¢</td>
          <td :class="{selected: usage_level === 2000}">{{ pricing_2000 && pricing_2000.avg_cents }}¢</td>
        </tr>
        </tbody>
      </table>
    </div>

    <div class="pricing" v-if="usage_calc">
      <strong>How your bill is calculated for {{ this.usage_level }} kWh Usage:</strong>
      <span>
        <strong>NOTE</strong>: Electricity pricing in Texas has two parts to it:
        <strong>Provider Charges ({{value.product.brand.name}})</strong> and
        <strong>Utility Delivery Charges ({{ value.tdsp.name }})</strong>.
        These combine to become <strong>Total Charges</strong>.
      </span>

      <div class="price_chart">
        <div class="price_chart_header">
          <strong>Provider Charges ({{ value.product.brand.name }})</strong>
        </div>
        <div class="charge_row"
             v-for="(charge, index) in usage_calc.charges.filter(x=> !x.component.tdsp_charge)" :key="index">
          <span>{{ charge_type(charge) }}</span>
          <span>{{ charge_description(charge) }}</span>
          <span>${{ charge.amount >= 0 ? charge.amount.toFixed(2) : `(${(charge.amount * -1).toFixed(2)})` }}</span>
        </div>
        <div class="subtotal_row">
          <strong>Subtotal: ${{
              usage_calc.charges.filter(x => !x.component.tdsp_charge).reduce((prev, curr) => prev + curr.amount, 0).toFixed(2)
            }}</strong>
        </div>
      </div>

      <div class="price_chart">
        <div class="price_chart_header">
          <strong>Utility Delivery Charges ({{ value.tdsp.name }})</strong>
        </div>
        <div class="charge_row"
             v-for="(charge, index) in usage_calc.charges.filter(x=> x.component.tdsp_charge)" :key="index">
          <span>{{ charge_type(charge) }}</span>
          <span>{{ charge_description(charge) }}</span>
          <span>${{ charge.amount >= 0 ? charge.amount.toFixed(2) : `(${(charge.amount * -1).toFixed(2)})` }}</span>
        </div>
        <div class="subtotal_row">
          <strong>Subtotal: ${{
              usage_calc.charges.filter(x => x.component.tdsp_charge).reduce((prev, curr) => prev + curr.amount, 0).toFixed(2)
            }}</strong>
        </div>
      </div>

      <div class="summary">
        <div class="total">Total Charges*: ${{ usage_calc.total.toFixed(2) }}</div>
        <div class="average">The average price you paid for electric service this month
          (${{ usage_calc.total }} ÷ {{ this.usage_level }} kWh):
          {{ usage_calc.avg_cents }}¢
        </div>
      </div>

      <div class="disclaimer">
        <p>
          *These estimates include all the recurring charges you'll see on your bill excluding government fees and
          taxes. Estimates are based on information provided by Frontier Utilities. These estimates should be confirmed
          using the information found in the Electricity Facts Label and Terms of Service. Actual price will vary
          according to your actual usage.
        </p>
      </div>
    </div>

    <div class="chart">
      <canvas :id="value._id"></canvas>
    </div>
  </div>
</template>

<script>
import pricing_service from "@/services/pricing";
import Chart from "chart.js/auto";

export default {
  name: "PlanDetails",
  props: {
    value: Object,
    usage: {
      type: Number,
      default: 1000
    }
  },
  data() {
    return {
      usage_calc: null,
      usage_level: this.usage,
      data: [],
      min: 0,
      max: 3000,
      step: 100
    }
  },
  computed: {
    pricing_500() {
      return this.data.find(x => x.usage === 500)
    },
    pricing_1000() {
      return this.data.find(x => x.usage === 1000)
    },
    pricing_2000() {
      return this.data.find(x => x.usage === 2000)
    }
  },
  mounted() {
    this.get_chart_data()
    this.calculate()
  },
  methods: {
    doc_type(value) {
      switch (value) {
        case 'efl':
          return 'Facts Label'
        case 'tos':
          return 'Terms of Service'
        case 'yraac':
          return 'YRAC'
        default:
          return value
      }
    },
    charge_type(charge) {
      if (charge.component.multiplicative) {
        if (charge.component.tdsp_charge) {
          return 'Delivery Charge'
        }
        else {
          return 'Energy Charge'
        }
      }
      else if (charge.component.amount >= 0) {
        if (charge.component.min === 0 && !charge.component.max) {
          return 'Base Fee'
        }
        else {
          return 'Usage Charge'
        }
      }
      else {
        return 'Bill Credit'
      }
    },
    charge_description(value) {
      if (value.component.multiplicative) {
        return `${Math.round(value.component.amount * 1000000) / 10000}¢ x ${value.calc_usage}`
      }
      else {
        return value.component.description
      }
    },
    async get_chart_data() {
      let datasets = [];
      let labels = []

      this.data = await pricing_service.plans.calculate({
        plan_id: this.value._id,
        usage_start: this.min,
        usage_end: this.max,
        step: this.step
      })
      datasets.push({
        type: 'bar',
        label: `${this.value.product.name} Total Price`,
        data: this.data.map(x => x.total),
        borderColor: `#09addd`,
        backgroundColor: `#09addd40`,
        yAxisID: 'total'
      })
      labels = this.data.map(x => x.usage)
      datasets.push({
        type: 'line',
        label: `${this.value.product.name} Average Price`,
        data: this.data.map(x => x.usage === 0 ? null : x.avg),
        borderColor: `#ec5a41`,
        backgroundColor: `#ec5a41`,
        yAxisID: 'avg'
      })
      console.log(this.value._id);
      let ctx = document.getElementById(this.value._id);
      console.log(datasets)
      this.chart = new Chart(
          ctx,
          {
            // type: 'line',
            data: {
              labels: labels,
              datasets: datasets
            },
            options: {
              responsive: true,
              scales: {
                x: {
                  display: true,
                  title: {
                    display: true,
                    text: 'Usage in kWh'
                  }
                },
                total: {
                  type: 'linear',
                  display: true,
                  position: 'left',
                  title: {
                    display: true,
                    text: 'Total Price in $'
                  }
                },
                avg: {
                  type: 'linear',
                  display: true,
                  position: 'right',
                  title: {
                    display: true,
                    text: 'Avg Price in $'
                  }
                }
              }
            }
          }
      )
    },
    async calculate() {
      this.usage_calc = await pricing_service.plans.calculate_usage({
        plan_id: this.value._id,
        usage: this.usage_level
      })
    }
  }
}
</script>

<style scoped>
.header {
  background-color: #eee;
  color: black;
  font-weight: 600;
  padding: 10px;
  font-size: 1.1em;
}

.docs {
  margin: 10px;
}

.docs a {
  margin-right: 10px;
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.etf {
  margin: 10px;
  padding-bottom: 10px;
  border-bottom: 1px #eee solid;
}

.etf span {
  margin-bottom: 5px;
  display: block;
}

.marketing {
  margin: 10px;
  padding-bottom: 10px;
}

.marketing strong {
  display: block;
  padding-bottom: 10px;
}

.marketing span {
  color: #02c602;
  font-weight: 600;
}

.pricing {
  margin: 10px;
}

.pricing span {
  display: block;
  padding-top: 5px;
  font-size: .9em;
}

.price_chart {
  margin-top: 10px;
}

.price_chart_header {
  padding-bottom: 5px;
}

.charge_row {
  display: flex;
  justify-content: space-between;
  padding: 5px 0px;
  border-bottom: 1px solid #eee;
}

.subtotal_row {
  display: flex;
  justify-content: right;
}

.subtotal_row strong {
  font-size: 1.2em;
}

.summary {
  border-top: 1px #eee solid;
  border-bottom: 1px #eee solid;
  margin-top: 10px;
  text-align: right;
  font-size: 1.2em;
  font-weight: 600;
}

.summary div {
  padding: 10px 0px;
}

.disclaimer {
  font-size: .8em;
}

.pricing_table table {
  width: 100%;
  margin: 0 auto;
  border-collapse: collapse;
  border-style: hidden;
  font-size: 1.1em;
}

.pricing_table table thead th {
  background-color: #f8f8f8;
  padding: 10px 20px;
}

.pricing_table table thead th button {
  background: black;
  color: white;
}

.pricing_table table thead th:first-of-type {
  width: 40%;
}

.pricing_table table tbody td {
  text-align: center;
  background-color: #f8f8f8;
  padding: 15px;
}

.pricing_table table td:first-of-type {
  text-align: left;
  font-weight: 600;
}

.pricing_table th, .pricing_table td {
  border: 1px solid #ddd;
}

.pricing_table th.selected, .pricing_table td.selected {
  background: white;
}

</style>