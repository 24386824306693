const base_path = process.env.VUE_APP_API_URL;
const queryString = require('query-string');
const axios = require('axios');

module.exports = {
    plans: {
        search: async ({current, brand_id, term, tdsp_duns, product_id, display_usage}) => {
            const {data} = await axios.get(`${base_path}/plans`, {
                params: {
                    current,
                    brand_id,
                    term,
                    tdsp_duns,
                    product_id,
                    display_usage,
                    disabled: false
                },
                paramsSerializer: params => {
                    return queryString.stringify(params)
                }
            });
            return data;
        },
        calculate: async ({plan_id, usage_start, usage_end, step}) => {
            const {data} = await axios.get(`${base_path}/plans/${plan_id}/chart/${usage_start}/${usage_end}/${step}`)
            return data;
        },
        calculate_usage: async ({plan_id, usage}) => {
            const {data} = await axios.get(`${base_path}/plans/${plan_id}/calculate/${usage}`);
            return data;
        }
    },
    products: {
        search: async function () {
            const {data} = await axios.get(`${base_path}/products`);
            return data;
        }
    },
    brands: {
        search: async function () {
            const {data} = await axios.get(`${base_path}/brands`);
            return data;
        }
    },
    tdsps: {
        search: async function () {
            const {data} = await axios.get(`${base_path}/tdsps`);
            return data;
        }
    }
}
