<template>
  <div class="featured_plan">
    <div class="banner">Holy crap this is amazing</div>
    <div class="details">
      <span style="padding-bottom: 10px;">{{ value.product.name }}</span>
      <img :src="`https://comparepower.com/media/providers/${value.product.brand.image_id}.png`"/>
      <span class="price_per_kwh">{{ value[`display_pricing_${usage}`].avg_cents }}¢</span>
      <span class="note">
        effective rate per kWh<br>
        (${{ value[`display_pricing_${usage}`].total }} ÷ {{ value[`display_pricing_${usage}`].usage }} kWh)
      </span>
    </div>
    <div class="features">
      <ul>
        <li>{{ value.product.term }} month</li>
        <li v-for="feature in value.product.features" :key="feature">{{ feature }}</li>
      </ul>
    </div>
    <div class="actions">
      <button class="check_availability">Check Availability</button>
      <span>or call</span>
      <a class="phone_number" href="tel:8667635084">{{ value.product.brand.phone_number }}</a>
      <span
          style="font-size: .7em; margin: 5px 0px;">{{ value.product.brand.name }} | {{ value.product.brand.puct_number }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "FeaturedPlan",
  props: {
    value: Object,
    usage: {
      type: Number,
      default: 1000
    }
  }
}
</script>

<style scoped>
.featured_plan {
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 220px;
  border: 1px #eee solid;
}

.banner {
  background-color: #154b62;
  color: white;
  text-align: center;
  font-width: 500;
  padding: 5px 10px;
}

.details {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.details span {
  margin: 5px 0px;
}

.details img {
  max-height: 30px;
}

.price_per_kwh {
  color: #09addd;
  font-size: 2.2em;
  font-weight: 600;
  display: block;
}

.note {
  color: #666;
  font-size: .8em
}

.features {
  border-top: 1px #eee solid;
  border-bottom: 1px #eee solid;
  font-size: .8em;
  margin: 10px 10px;
}

.features ul {
  padding-left: 20px
}

.actions {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.check_availability {
  background-color: #ec5a41;
  color: white;
  border: none;
  font-weight: 600;
  padding: 15px 20px;
  text-transform: uppercase;
}

.phone_number {
  color: #ec5a41;
  font-weight: 600;
  font-size: 1.2em;
}
</style>