<template>
  <div class="plan">
    <div class="plan_card">
      <div class="left">
        <img class="logo" :src="`https://comparepower.com/media/providers/${value.product.brand.image_id}.png`"/>
        <span class="puct_number">PUCT # 10027</span>
        <button class="plan_details_toggle" @click="view_details_clicked">View Plan Details</button>
      </div>
      <div class="center">
        <div class="center-top">
          <div>
            <strong>{{ value.product.term > 0 ? `${value.product.term} month` : 'Monthly' }}</strong>
            <span class="note">Locked Rate</span>
            <span class="note">{{ value.product.percent_green }}% Green Energy</span>
          </div>
          <div>
            <strong>${{ display_pricing.total }}</strong>
            <span class="note">monthly bill estimate for {{ display_pricing.usage }} kWh usage*</span>
          </div>
          <div>
            <strong>{{ display_pricing.avg_cents }}¢</strong>
            <span class="note">
              effective rate per kWh (${{
                display_pricing.total
              }} ÷ {{ display_pricing.usage }} kWh)
            </span>
          </div>
        </div>
        <div class="center-bottom">
          <strong>{{ value.product.name }}</strong><br>
          <span class="note">{{ value.product.description }}</span>
        </div>
      </div>
      <div class="right">
        <span class="price_per_kwh">{{ display_pricing.avg_cents }}¢</span>
        <span style="color: #999">per kwh</span>
        <a :href="`https://orders.dev.comparepower.com?plan_id=${value._id}`" target="_blank">
          <button class="check_availability">Check Availability</button>
        </a>
        <span>or call</span>
        <a href="tel:8667635084">{{ value.product.brand.phone_number }}</a>
      </div>
    </div>
    <plan-details v-if="visible" :value="value" :usage="usage"/>
  </div>
</template>

<script>
import PlanDetails from "@/components/PlanDetails";

export default {
  name: "Plan",
  components: { PlanDetails },
  props: {
    value: Object,
    usage: {
      type: Number,
      default: 1000
    }
  },
  computed: {
    display_pricing() {
      let found = this.value[`display_pricing_${this.usage}`];
      if (!found) {
        found = this.value['display_pricing_1000'];
      }
      return found;
    }
  },
  data() {
    return {
      visible: false
    }
  },
  methods: {
    view_details_clicked() {
      this.visible = !this.visible;
      this.$emit('view_details_clicked', this.value)
    }
  }
}
</script>

<style scoped>

.plan {
  background-color: white;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  /*max-width: 40vw;*/
  padding: 1px;
}

.plan:hover {
  border: 1px solid #aaa;
  padding: 0;
}

.spacer {
  background-color: #eee;
  color: black;
  font-weight: 600;
  padding: 10px 20px;
}

.plan_card {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 200px;
  font-family: 'proxima-nova', Arial, Helvetica, sans-serif;
}

.left, .right, .center {
  padding: 5px 10px
}

.left {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo {
  display: block;
  max-width: 100px;
}

.puct_number {
  display: block;
  font-weight: 600;
  font-size: .7em;
  padding-top: 3px;
}

.plan_details_toggle {
  background-color: #eee;
  border: none;
  font-size: .9em;
  font-weight: 100;
  width: 150px;
  padding: 10px 15px;
  margin-top: 15px;
}

.center-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.note {
  color: #666;
  font-size: .8em;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.center-top > div {
  margin: 0px 5px;
  display: flex;
  flex-direction: column;
}

.center-top > div span {
  display: block;
}

.center-bottom {
  padding-top: 20px;
}

.right {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.price_per_kwh {
  color: #09addd;
  font-size: 2.2em;
  font-weight: 600;
  display: block;
}

.check_availability {
  cursor: pointer;
  background-color: #ec5a41;
  color: white;
  border: none;
  font-weight: 600;
  min-width: 180px;
  padding: 15px;
  text-transform: uppercase;
  margin: 15px 0px;
}
</style>
