<template>
  <div class="container" v-if="plans">
    <div class="left">

      <div class="filter terms">
        <h4>
          Contract Length<br>
          (select all that apply)
        </h4>
        <div class="options">
          <div>
            <input id="term_none" type="checkbox" :checked="no_terms_selected" @click="clear_term_filter"/>
            <label for="term_none"> Show All </label>
          </div>
          <div v-for="term in terms" :key="term">
            <input :id="`term_${term}`" type="checkbox" v-model="selected_terms" :value="term"
                   @change="get_plans_and_sort"/>
            <label :for="`term_${term}`"> {{ term > 0 ? `${term} months` : 'Monthly/No-Contract' }} </label>
          </div>
        </div>
      </div>

      <div class="filter brands">
        <h4>
          Provider<br>
          (select all that apply)
        </h4>
        <div class="options">
          <div>
            <input id="brand_none" type="checkbox" :checked="no_brands_selected" @click="clear_brand_filter"/>
            <label for="brand_none">Show All</label>
          </div>
          <div v-for="brand in brands" :key="brand._id">
            <input :id="`brand_${brand.name}`" type="checkbox" v-model="selected_brands" :value="brand._id"
                   @change="get_plans_and_sort"/>
            <label :for="`brand_${brand.name}`">{{ brand.name }}</label>
          </div>
        </div>
      </div>
    </div>
    <div class="right">

      <!--Featured Plan Section-->
      <div class="right_section">
        <span class="header">Featured Plans</span>
        <div class="featured_plans">
          <featured-plan v-for="plan in plans.filter(x=> x.product.featured)" :key="plan._id"
                         :value="plan" :usage="usage_level"></featured-plan>
        </div>
      </div>

      <!--Usage Selector-->
      <div class="right_section usage_selector">
        <p>
          Choose your home type or enter your estimated monthly usage to see example monthly cost.
        </p>
        <div>
          <button @click="usage_level = 500" :class="{selected: usage_level === 500}">
            <strong>Apartment</strong>
            <span>500 kWh</span>
          </button>
          <button @click="usage_level = 1000" :class="{selected: usage_level === 1000}">
            <strong>Small Home</strong>
            <span>1000 kWh</span>
          </button>
          <button @click="usage_level = 2000" :class="{selected: usage_level === 2000}">
            <strong>Large Home</strong>
            <span>2000 kWh</span>
          </button>
          <button :class="{selected: ![500,1000,2000].includes(usage_level) && custom_usage_selected}"
                  @click="custom_usage_changed">
            <strong>Your Home</strong>
            <span>
              <input v-model="custom_usage" @keyup="custom_usage_changed" placeholder="Usage"/> kWh
            </span>
          </button>
        </div>
      </div>

      <div class="right_section">
        <span class="header">
          <strong>{{ plans.length }}</strong> electric rates for zip code 77379 (Centerpoint)
        </span>

        <div class="header" style="display: flex; justify-content: right">
          <div>
            <label style="display: block">Sort By</label>
            <select v-model="sort_option" @change="sort_plans" style="padding: 10px">
              <option value="price_desc">Price (low to high)</option>
              <option value="price_asc">Price (high to low)</option>
              <option value="term_desc">Term/Contract Length (shorter to longer)</option>
              <option value="term_asc">Term/Contract Length (longer to shorter)</option>
            </select>
          </div>
        </div>

        <div class="filtered_plans">
          <div v-for="plan in plans" :key="plan._id">
            <plan :value="plan" :usage="usage_level"></plan>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import pricing_service from "@/services/pricing";
import FeaturedPlan from "@/components/FeaturedPlan";
import Plan from "@/components/Plan";

export default {
  name: "Home",
  components: {Plan, FeaturedPlan},
  computed: {
    no_brands_selected() {
      return this.selected_brands.length === 0
    },
    no_terms_selected() {
      return this.selected_terms.length === 0
    }
  },
  data() {
    return {
      sort_option: 'price_desc',
      selected_brands: [],
      brands: [],
      terms: [-1, 12, 24, 36],
      selected_terms: [],
      usage_level: 1000,
      selected_plan: null,
      plans: [],
      custom_usage: null,
      custom_usage_selected: false,
      usage_history: {
        jan: null,
        feb: null,
        mar: null,
        apr: null,
        may: null,
        jun: null,
        jul: null,
        aug: null,
        sep: null,
        oct: null,
        nov: null,
        dec: null
      }
    }
  },
  async mounted() {
    await this.get_plans_and_sort();
    this.brands = await pricing_service.brands.search();
  },
  methods: {
    async clear_brand_filter() {
      this.selected_brands = [];
      await this.get_plans()
    },
    async clear_term_filter() {
      this.selected_terms = [];
      await this.get_plans()
    },
    async custom_usage_changed() {
      if (this.custom_usage) {
        this.custom_usage_selected = true;
        await this.get_plans();
        this.usage_level = parseInt(this.custom_usage);
        await this.sort_plans()
      }
    },
    async get_plans_and_sort() {
      await this.get_plans();
      await this.sort_plans()
    },
    async get_plans() {
      this.plans = await pricing_service.plans.search({
        term: this.selected_terms,
        brand_id: this.selected_brands,
        current: true,
        tdsp_duns: '957877905',
        display_usage: [this.custom_usage ? this.custom_usage : undefined, ...Object.values(this.usage_history).filter(x => !!x)]
      });

    },
    sort_plans() {
      const split = this.sort_option.split('_');
      const type = split[0];
      const direction = split[1];
      const multiplier = direction === 'desc' ? -1 : 1;
      this.plans = this.plans.sort((a, b) => {
        let value_a, value_b;
        if (type === 'price') {
          value_a = a[`display_pricing_${this.usage_level}`].avg;
          value_b = b[`display_pricing_${this.usage_level}`].avg;
        }
        else {
          value_a = a.product.term;
          value_b = b.product.term;
        }

        if (value_a > value_b)
          return -1 * multiplier
        else
          return 1 * multiplier;
      })
    }
  }
}
</script>

<style scoped>

.container {
  display: flex;
  justify-content: center;
}

.left {
  /*min-width: 400px;*/
}

.right {
  max-width: 1000px;
}

.right_section {
  background-color: #f8f8f8;
  padding: 10px 20px;
  margin-bottom: 10px;
  color: #333;
}

.right_section .header {
  display: block;
  font-weight: 300;
  font-size: 1.2em;
  padding: 10px 0px;
}

.usage_selector button {
  border: none;
  padding: 8px 14px;
  margin: 2px;
  cursor: pointer;
}

.usage_selector button.selected {
  background-color: #09addd;
  color: white;
}

.usage_selector button span {
  display: block;
  padding-top: 5px;
}

.usage_selector button input {
  display: inline-block;
  background: inherit;
  border: 0;
  outline: 0;
  width: 40px;
  text-align: right;
}

.usage_selector button.selected input {
  color: white;
}

.usage_selector button input:focus {
  outline: none !important;
}

.featured_plans {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: flex-start;
}

.featured_plans > div {
  margin: 5px;
}

.filter {
  color: #09addd;
  font-size: .9em;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: left;
}

.filter .options > div {
  padding: 5px
}

.filter .options > div > label {
  font-size: 1.2em;
  font-weight: bold;
  padding-left: 1.5em;
  text-indent: -.7em;
  display: inline-block;
}

input[type=checkbox] {
  transform: scale(1.5);
}

</style>
