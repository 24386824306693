<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>

export default {
  name: 'App',
  data() {
    return {menu_items: []}
  }
}
</script>

<style>

</style>
